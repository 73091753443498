@import 'flatpickr/dist/flatpickr';
@import 'highlight.js/scss/vs2015';
@import 'quill/dist/quill.core.css';
@import 'select2/dist/css/select2.css';
@import 'daterangepicker/daterangepicker.css';
//@import 'theme-dark.scss';
@import 'theme.scss';
body {
    nav {
        .nav-collapse-toggler {
            display: flex;
            z-index: 1000;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            border: 2px solid white;
            border-radius: 100%;
            background: lightgray;
            box-shadow: 2px 1px 1px gray;
            transition: 0.2s all;
            color: black;
        }
        .nav-collapse-toggler:hover {
            background: gray;
            color: white;
        }
    }
    // .signin-container {
    //     height: 100vh;
    // }
}
.navbar-hide {
    display: none !important;
}
.cursor-pointer {
    cursor: pointer;
}
.text-decoration-none {
    text-decoration: none !important;
}

// Alerts
.alert {
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0px;
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        .alert-icon {
            margin: 0px 15px 0px 25px;
        }
        .alert-message {
            padding: 0px;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 25px;
            p {
                padding: 0px;
                margin: 0px;
            }
        }
    }
    .alert-button-warning {
        color: white;
        > .btn-close {
            width: 35px;
            height: 35px;
            color: white;
        }
    }
}

// Custom width
.w-5 {
    width: 5%;
}
.w-10 {
    width: 10%;
}
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-35 {
    width: 35%;
}
.w-40 {
    width: 40%;
}
.w-45 {
    width: 45%;
}
.w-55 {
    width: 55%;
}
.w-60 {
    width: 60%;
}
.w-65 {
    width: 65%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}
.w-85 {
    width: 85%;
}
.w-90 {
    width: 90%;
}
.w-95 {
    width: 95%;
}

// Checkbox border
.checkbox-dark {
    > input {
        background: #cdd4df;
        border: 1px solid #a6acb6;
    }
}
.text-right {
    text-align: right;
}

.text-xsmall {
    font-size: x-small;
}

.text-xxsmall {
    font-size: xx-small;
}

.text-small {
    font-size: small;
}
.js-weekly-table-data {
    cursor: pointer;
    &:hover {
        color: #698ac0
    }
}
.timelog-calendar-header {
    height: 4rem;
}

.font-weight-normal {
    font-weight: normal;
}

.word-break-all {
    word-break: break-all;
}

.select2-container {
    .select2-selection.select2-selection--single {
        height: 40px;
        .select2-selection__arrow {
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 40px!important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    height: 40px!important;
}

.error-message {
    color: #d35a5a;
    font-size: 14px;
    padding: 2px;
    display: none;

    &.displayed {
        display: block !important;
    }
}

table.workspace-table {
    tbody td {
        font-size: $font-size-base !important;
    }
    p {
        font-size: $font-size-base !important;
    }
 }

 #timelineSummary {
    div.timeline-summary-item {
        @extend .border-top, .d-flex, .flex-column, .flex-xxl-row,
                .justify-content-between, .pt-3, .mt-3, .gap-3;
    }

    div.timeline-summary-date {
        @extend .d-flex, .flex-column, .gap-3;
        width: 100%;
    }
 }

@media only screen and (min-width: 1400px){
    div.timeline-summary-date {
        width: 50% !important;
    }
}

.sla-dashboard {
    table {
        th, td {
            border: 1px solid rgba($color: $gray-300, $alpha: 1.0);;
            border-collapse: collapse;
            padding: 0 3px;

            &:nth-child(n+2) {
                text-align: center !important;
            }
        }

        td {
            &:nth-child(n+2) {
                border: 1px solid rgba($color: $gray-300, $alpha: 0.5);
            }
        }
    }
}

.divide-x {
    *::after {
        content: '';
        border-left: 1px solid #D9D9D9;
        margin-left: 1rem;
    }

    *:last-child:after {
        display: none;
    }
}

.preset-filter {
    @extend .btn, .border, .rounded-pill;
    padding: .2rem 1rem;
    color: inherit;

    &.active {
        color: $red !important;
        background-color: rgba($color: $red, $alpha: 0.1);

        &::after {
            content: "x";
            margin-left: 6px;
        }
    }
}

select.select2-sm + span.select2-container .select2-selection--single {
    height: 25px !important;

    .select2-selection__rendered {
        line-height: 22px !important;

    }
    .select2-selection__clear {
        height: 20px !important;
    }
    .select2-selection__arrow {
        margin-top: 0 !important;
    }
}

.flatpickr-disabled {
    text-decoration: line-through !important;
    opacity: .5;
}